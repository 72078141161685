<template>
  <div class="collection-item-border ">
    <div class="collection-item-theme collection-theme-4" @click="handleClick">
      <div class="collection-item-sources">
        <img v-lazy="item.headImg?.[0]" alt="">
      </div>
      <div class="collection-item-content">
        <div class="news-info">
          <div v-if="item.avatar" class="author-avatar" />
          <div class="author-name">
            {{ item.channel }} |
            {{ $global.timeAgo($global.dateStringToTimestamp(item.published)) }}
          </div>
        </div>
        <div class="title">
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/css/module/collection/theme_4.scss';

export default {
  props: {
    item: {
      required: true,
      type: Object,
    },
  },
  methods: {
    handleClick () {
      this.$emit('customClick');
    }
  }
};
</script>
